import React from 'react';
import logo from './logo.svg';
import './styles/views/technology.less';
import './styles/views/product.less';
import './styles/views/company.less';
import './styles/views/order.less';
import './styles/modules/overrides.less';

import './styles/vendor/bootstrap.less';
import './styles/animations/tada.css';
import './styles/includes/mixins.less';
import './styles/views/main.less';

import './App.css';
import './styles/style.css';
import './styles/app.less';
import './styles/temp_style.css';
import MainTopPanel from "./components/top_panel";
import MainContent from "./components/box_content";




function App() {
	return (
		<div className="App">
			
			
			<MainTopPanel/>
			<MainContent/>
		</div>
	);
}

export default App;
