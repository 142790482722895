import React from 'react';

const MainTopPanel = () => {
	return (
		<>
			<div className="navbar navbar-fixed-top">
				<div className="wrapper"><a className="home" href="/ru/">
					<h1>Shopster</h1></a>
					<nav>
						<ul>
							<li> <a href="tel:+7 499 455-45-16">+7 (499) 455-45-16 </a></li>
							<li> <a href="mailto:info@getshopster.net">info@getshopster.net</a></li>
						</ul>
					</nav>
					<div className="buttons"><a className="order" href="http://wiki.getshopster.net">Wiki</a><a className="login" href="https://client.getshopster.net" target="_blank">Личный кабинет</a></div>
				</div>
			</div>
			
		</>
	);
};

export default MainTopPanel;
