import React, {useState} from 'react';


import Document1 from './docemunts/Аналитика (полное).docx.pdf'
import Document2 from './docemunts/Shopster. Hotspot (полное).docx.pdf'
import Document3 from './docemunts/Shopster. Outdoor.pdf'
import Document4 from './docemunts/Shopster. Аналитика.pdf'
import styled from "styled-components/macro";
// import PDFViewer from 'pdf-viewer-reactjs';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';



const WrapperPdf = (props) => {
	
	let objDocument = {
		1: Document1,
		2: Document2,
		// 3: Document3,
		// 4: Document1
	}

	const defaultLayoutPluginInstance = defaultLayoutPlugin();

	const onLoadDocument = (error) => {
		console.log('>>>>>>>>>', error)
	}



	const renderDocuments = (idDocument) => {
		let newVar;
		console.log('>>>>>>>>>', idDocument, objDocument)
		if (idDocument === 1) {
			newVar = (
				<Viewer
					fileUrl={objDocument[1]}
					plugins={[
						// Register plugins
						defaultLayoutPluginInstance,

					]}
					// onDocumentLoad={onLoadDocument}
					LoadError={onLoadDocument}
				/>
			);
		}
		else if (idDocument === 2) {
			newVar = (
				<Viewer
					fileUrl={objDocument[2]}
					plugins={[
						// Register plugins
						defaultLayoutPluginInstance,

					]}
					// onDocumentLoad={onLoadDocument}
					LoadError={onLoadDocument}
				/>
			);
		}
		else if (idDocument === 3) {
			newVar = (
				<Viewer
					fileUrl={objDocument[3]}
					plugins={[
						// Register plugins
						defaultLayoutPluginInstance,

					]}
					// onDocumentLoad={onLoadDocument}
					LoadError={onLoadDocument}
				/>
			);
		}
		else if (idDocument === 4) {
			newVar = (
				<Viewer
					fileUrl={objDocument[4]}
					plugins={[
						// Register plugins
						defaultLayoutPluginInstance,

					]}
					// onDocumentLoad={onLoadDocument}
					LoadError={onLoadDocument}
				/>
			);
		}

		return newVar;

	}
	
	
	return (
		<WrapperPdfStyled>
			{/*<PDFViewer*/}
			{/*	document={{*/}
			{/*		url: Document1,*/}
			{/*	}}*/}
			{/*	hideRotation={true}*/}
			{/*/>*/}
			{
				props.idDocument > 0  ?
					<Worker
						workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
					>
						{renderDocuments(props.idDocument)}
					</Worker> :
					null
			}
			
		</WrapperPdfStyled>
	);
}

const WrapperPdfStyled = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
`

export default WrapperPdf;