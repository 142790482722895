import React, { useState } from 'react';
import styled from 'styled-components';
import { Map, Placemark, YMaps } from 'react-yandex-maps';

import YouTube from 'react-youtube';
import Image from '../../img/Screenshot 2020.png';
import ImageCarousel1 from '../../img/Screenshot 2020-11-13 1.png';
import ImageCarousel2 from '../../img/Screenshot 2020-11-13 2.png';
import ImageCarousel3 from '../../img/Screenshot 2020-11-13 3.png';
import ImageCarousel4 from '../../img/Screenshot 2020-11-13 4.png';
import ImageCarousel5 from '../../img/Screenshot 2020-11-13 5.png';
import ImageCarousel6 from '../../img/Screenshot 2020-11-13 6.png';
import ImageCarousel7 from '../../img/Screenshot 2020-11-13 7.png';
import ImageCarousel8 from '../../img/Screenshot 2020-11-13 8.png';
import ImageProfile from '../../img/hotspot-profile.png';
import ImageSegment from '../../img/segments.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'leaflet/dist/leaflet.css';
import MainFunctional from './components/functional';

let arrayCarousel = [
	ImageCarousel1,
	ImageCarousel2,
	ImageCarousel3,
	ImageCarousel4,
	ImageCarousel5,
	ImageCarousel6,
	ImageCarousel7,
	ImageCarousel8,
];

const BoxModal = styled.div`
	display: ${(p) => (p.statusOpenModal ? 'block' : 'none')};
	background: rgba(0, 0, 0, 0.4);
`;

const SpanList = styled.span`
	display: block;
	width: 100%;
	text-align: left;
	font-size: 18px;
`;

const SpanListTitle = styled.span`
	display: block;
	width: 100%;
	font-size: 18px;
`;

const DivBoxListItem = styled.div`
	display: grid;
	grid-template-columns: 625px;
	width: 100%;
	justify-content: center;
`;

const DivBoxText = styled.div`
	padding-top: 40px;
`;

const ImageBox = styled.img`
	width: 100%;
`;

const MainContent = () => {
	const [statusOpenModal, setStatusOpenModal] = useState(false);
	const [serverState, setServerState] = useState(null);
	const [status, setStatus] = useState(null);

	const stateMaps = { center: [55.692226, 37.347679], zoom: 15 };
	const submitForm = (ev) => {
		ev.preventDefault();
		const form = ev.target;
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			if (xhr.status === 200) {
				form.reset();
				setStatus('SUCCESS');
			} else {
				setStatus('ERROR');
			}
		};
		xhr.send(data);
	};

	const openModal = (e) => {
		setStatusOpenModal(true);
	};

	const hideModal = (e) => {
		if (e.target.id === 'orderModal' || e.target.id === 'close') {
			setStatusOpenModal(false);
		}
	};

	const opts = {
		height: '554',
		width: '980',
		// playerVars: {
		// 	// https://developers.google.com/youtube/player_parameters
		// 	autoplay: 1,
		// },
	};
	let componentNodeImage = [];
	arrayCarousel.forEach((item, index) => {
		componentNodeImage.push(
			<div>
				<img src={item} />
				{/*<p className="legend">Legend 3</p>*/}
			</div>,
		);
	});
	if (document.location.pathname === '/functional') {
		return (
			<>
				<div className={'tempHeader'}></div>
				<MainFunctional />
				<div
					className='center'
					style={{
						paddingBottom: '40px',
						borderTop: '1px solid #ccc',
					}}
				>
					<DivBoxText>
						<DivBoxListItem>
							<SpanListTitle>
								<p>Стоимость ПО определяется индивидуально.</p>
								<p>
									Для получения коммерческого предложения,
									отправьте заявку на почту{' '}
									<a href='mailto:info@getshopster.net'>
										info@getshopster.net
									</a>
									. В заявке необходимо указать следующие
									данные:
								</p>
							</SpanListTitle>
						</DivBoxListItem>
						{/*<DivBoxListItem>*/}
						{/*	<SpanListTitle>*/}
						{/*		<a href="mailto:info@getshopster.net">info@getshopster.net</a>*/}
						{/*	</SpanListTitle>*/}
						{/*</DivBoxListItem>*/}
						{/*<DivBoxListItem>*/}
						{/*	<SpanList>В заявке необходимо указать следующие данные:</SpanList>*/}
						{/*</DivBoxListItem>*/}
						<DivBoxListItem>
							<SpanList>
								<ul>
									<li>
										<p>Юридическое наименование компании</p>
									</li>
									<li>
										<p>
											Какой тип бизнеса вы представляете
											(ТРЦ/Ритейл/HoReCa/Другое)
										</p>
									</li>
									<li>
										<p>
											Контактные данные для связи с вами
										</p>
									</li>
									<li>
										<p>
											К заявке дополнительно необходимо
											приложить План объекта
										</p>
									</li>
								</ul>
							</SpanList>
						</DivBoxListItem>
						{/*<DivBoxListItem>*/}
						{/*	<SpanList>2. Какой тип бизнеса вы представляете (ТРЦ/Ритейл/HoReCa/Другое)</SpanList>*/}
						{/*</DivBoxListItem>*/}
						{/*<DivBoxListItem>*/}
						{/*	<SpanList>3. Приложите план объекта с масштабами</SpanList>*/}
						{/*</DivBoxListItem>*/}
						{/*<DivBoxListItem>*/}
						{/*	<SpanList>4. Укажите контактные данные для связи с вами</SpanList>*/}
						{/*</DivBoxListItem>*/}
					</DivBoxText>

					<button
						onClick={openModal}
						className='btn btn-primary btn-lg'
						data-toggle='modal'
						data-target='#orderModal'
						style={{
							marginBottom: '40px',
							marginTop: '20px',
						}}
					>
						Оставить заявку
					</button>
					<div className='container'>
						<h1 className='center'>ОСНОВНЫЕ КОНТАКТЫ</h1>
						<p>
							Тел.:{' '}
							<a href='tel:+7 499 455-45-16'>
								+7 (499) 455-45-16
							</a>
						</p>
						{/*<p>Телефон службы поддержки: <a href="tel:+7 495 114-90-28,705">+7 495 114-90-28 доб. 705</a></p>*/}
						<p>
							E-mail:{' '}
							<a href='mailto:info@getshopster.net'>
								info@getshopster.net
							</a>
						</p>
						<p>
							Адрес: 121205, г. Москва, территория инновационного
							центра «Сколково»,Большой бульвар, д. 42, стр. 1, 3
							эт., помещение №1112, раб. место №1
						</p>
					</div>
				</div>
				<div className='boxLaflet'>
					<YMaps>
						<div className={'boxLafletMaps'}>
							<Map
								width={'100%'}
								height={'100%'}
								defaultState={stateMaps}
							>
								<Placemark geometry={stateMaps.center} />
							</Map>
						</div>
					</YMaps>
				</div>
				<div
					className='center'
					style={{
						paddingBottom: '40px',
						borderTop: '1px solid #ccc',
					}}
				>
					<div className='container'>
						<table style={{ margin: '60px auto' }}>
							<tbody>
								<tr>
									<td>СВЕДЕНИЯ</td>
									<td>ИМЕЮЩИЕСЯ ДАННЫЕ</td>
								</tr>
								<tr>
									<td>
										Полное наименование юридического лица
									</td>
									<td>
										Общество с ограниченной ответственностью
										«Шопстер Технологии»
									</td>
								</tr>
								{/*<tr>*/}
								{/*	<td>Форма собственности</td>*/}
								{/*	<td>ООО</td>*/}
								{/*</tr>*/}
								<tr>
									<td>ИНН / КПП</td>
									<td>7706457835/773101001</td>
								</tr>
								<tr>
									<td>ОГРН</td>
									<td>1187746825496</td>
								</tr>
								<tr>
									<td>Генеральный директор</td>
									<td>Котелевская Анна Алексеевна</td>
								</tr>
								<tr>
									<td>Юридический адрес</td>
									<td>
										121205, г. Москва, территория
										инновационного центра «Сколково»,
										Большой бульвар, д. 42, стр. 1, 3 эт.,
										помещение №1112, раб. место №1
									</td>
								</tr>
								{/*<tr>*/}
								{/*	<td>Почтовый адрес</td>*/}
								{/*	<td>123007, Москва, Хорошевское шоссе, 32А, корп. 24 (подъезд 2, этаж 2)</td>*/}
								{/*</tr>*/}
							</tbody>
						</table>
					</div>
				</div>
			</>
		);
	}

	return (
		<div className={'containerContent'}>
			<div className='header'>
				<div className='dig-bick'>
					<h1>
						Получайте качественно новую информацию о своих
						посетителях и зарабатывайте больше, используя передовые
						инструменты proximity-маркетинга
					</h1>
					<p>
						{' '}
						<span href='#analytics'>Wi-Fi Аналитика </span> |{' '}
						<span href='#hotspot'>Социальный Wi-Fi</span> |{' '}
						<span href='#ibeacon'>iBeacon™ </span> |{' '}
						<span href='#navigation'>Indoor-Навигация</span>
					</p>
					<div className='slideshow-wrapper'>
						<div className='iphone' />
						<div className='router' />
						<div className='slideshow'>
							<div className='slides'>
								<ImageBox src={Image} />
							</div>
						</div>
					</div>
				</div>
				<div className='center'>
					<p className='press'>
						<a
							className='pub pub-lenta'
							href='http://lenta.ru/news/2013/10/21/wifi/'
							target='_blank'
						/>
						<a
							className='pub pub-hf'
							href='http://www.hopesandfears.com/hopesandfears/news/news/123137-shopster'
							target='_blank'
						/>
						<a
							className='pub pub-siliconrus'
							href='https://vc.ru/flood/2203-mall-tracking'
							target='_blank'
						/>
						<a
							className='pub pub-vedomosti'
							href='http://www.vedomosti.ru/companies/news/17708521/pokupatel-pod-kontrolem'
							target='_blank'
						/>
						<br />
						<a
							className='pub pub-m24'
							href='http://www.m24.ru/videos/32000'
							target='_blank'
						/>
						<a
							className='pub pub-village'
							href='http://www.the-village.ru/village/service-shopping/service-shopping/133381-shopster'
							target='_blank'
						/>
					</p>
				</div>
			</div>
			<hr />
			<div className={'containerCenterChild'}>
				<div className='container index_solutions'>
					<div className='center'>
						<h1 className='center'>Что такое Shopster?</h1>
					</div>
					<div className='row'>
						<div className='col-md-8 right'>
							<h3>Многофункциональные роутеры</h3>
							<p>
								Роутеры Shopster, оснащенные маячками iBeacon™,
								одновременно раздают Wi-Fi, отслеживают
								перемещения покупателей и показывают им рекламу
								на стартовой странице WiFi и в мобильных
								приложениях.
							</p>
						</div>
						<div className='col-md-4 center'>
							<img src='/img/index_own.png' />
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4 center'>
							<img
								src='/img/index_analytics.png'
								style={{ marginTop: '60px' }}
							/>
						</div>
						<div className='col-md-8 left'>
							<h3>Wi-Fi-аналитика</h3>
							<p>
								Shopster Analytics отслеживает потоки
								посетителей путем анализа Wi-Fi-сигналов
								мобильных устройств ваших посетителей. Система
								даст вам качественно новую информацию о них:
								частоту посещений, проведенное в магазине время,
								вовлеченность и многое другое. Вашим гостям не
								требуется подключаться к Wi-Fi, а лишь
								достаточно иметь с собой включенное устройство.
							</p>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-8 right'>
							<h3>Indoor-навигация и маячки iBeacon™</h3>
							<p>
								Роутеры Shopster, оснащены маячками iBeacon, с
								помощью которых мобильные приложения могут с
								высокой точностью определять местоположение
								покупателей и отправлять им рекламные сообщения.
								Мы гордимся тем, что разработали продвинутую
								технологию навигации внутри зданий и первыми в
								России внедрили ее в одном из крупнейших
								торговых центров Москвы.
							</p>
						</div>
						<div className='col-md-4 center'>
							<img
								src='/img/index_ibeacons.png'
								style={{ marginTop: '30px' }}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4 center'>
							<img
								src='/img/main-Shopster_Hotpot.png'
								style={{ marginTop: '50px' }}
							/>
						</div>
						<div className='col-md-8 left'>
							<h3>
								Брендированный Wi-Fi с социальной авторизацией
							</h3>
							<p>
								С помощью Shopster Hotspot вы можете настроить
								собственную стартовую страницу Wi-Fi с
								авторизацией через SMS и соц. сети. Подключаясь
								к Wi-Fi, ваши гости будут оставлять рекламные
								сообщения на своих страницах, а вы получите
								детальную информацию о них: пол, возраст,
								интересы и др и использовать эти данные для
								увеличения продаж.
							</p>
						</div>
					</div>
				</div>

				<div className='white-bg'>
					<div className='container analytics'>
						<div className='center'>
							<a name='analytics' />
							<h1 className='center'>Wi-Fi-аналитика</h1>
						</div>
						<div className='row segments'>
							<Carousel>{componentNodeImage}</Carousel>
							{/*<div className="col-md-12 center"><img src="/img/screens.png" /></div>*/}
						</div>
						<div className='center'>
							<h2>
								Shopster Analytics отслеживает жизненно важные
								метрики вашего оффлайн-бизнеса: длительность
								посещений, количество уникальных посетителей,
								лояльность, вовлеченность, динамику и многое
								другое:
							</h2>
						</div>
						<div className='row features'>
							<div className='col-md-4'>
								<div
									className='feature'
									style={{ background: '#43358a' }}
								>
									<img
										className='icon'
										src='/img/icon-door.png'
									/>
									<h3>
										Конверсия <span>Entry Rate</span>
									</h3>
									<div className='description'>
										Сколько людей, проходящих мимо вашего
										оффлайн-бизнеса, заходят к вам
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div
									className='feature'
									style={{ background: '#07ce8f' }}
								>
									<img
										className='icon'
										src='/img/icon-time.png'
									/>
									<h3>
										Время&nbsp;<span>Dwell Time</span>
									</h3>
									<div className='description'>
										Сколько времени покупатели проводят в
										разных секциях магазина
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div
									className='feature'
									style={{ background: '#71d130' }}
								>
									<img
										className='icon'
										src='/img/icon-heartbeat.png'
									/>
									<h3>
										Частота&nbsp;<span>(Frequency)</span>
									</h3>
									<div className='description'>
										Как часто к вам приходят одни и те же
										люди
									</div>
								</div>
							</div>
						</div>
						<div className='row features'>
							<div className='col-md-4'>
								<div
									className='feature'
									style={{ background: '#ffc000' }}
								>
									<img
										className='icon'
										src='/img/icon-retention.png'
									/>
									<h3>
										Динамика&nbsp;<span>(Retention)</span>
									</h3>
									<div className='description'>
										Растет или уменьшается количество
										постоянных покупателей
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div
									className='feature'
									style={{ background: '#1d8ff3' }}
								>
									<img
										className='icon'
										src='/img/icon-path.png'
									/>
									<h3>
										Маршруты&nbsp;<span>(Routes)</span>
									</h3>
									<div className='description'>
										Как гости перемещаются внутри вашего
										заведения
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div
									className='feature'
									style={{ background: '#8393ca' }}
								>
									<img
										className='icon'
										src='/img/icon-heatmaps.png'
									/>
									<h3>
										Зоны&nbsp;<span>(Heat Maps)</span>
									</h3>
									<div className='description'>
										Какие отделы вашего магазина пользуются
										популярностью, а куда заходят редко
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='center'>
						<h2>Как это работает?</h2>
					</div>
					<div className='container'>
						<div
							className='row'
							style={{ marginTop: '40px', marginBottom: '40px' }}
						>
							<div className='col-md-3 center'>
								<div
									className='white-bg'
									style={{ padding: '10px' }}
								>
									<h4
										style={{
											// height: '135px',
											// display: 'table-cell',
											verticalAlign: 'middle',
										}}
									>
										Мобильные устройства в поисках Wi-Fi
										излучают анонимные сигналы
									</h4>
									<img src='/img/hand.png' />
								</div>
							</div>
							<div className='col-md-3 center'>
								<div
									className='white-bg'
									style={{ padding: '10px' }}
								>
									<h4
										style={{
											// height: '135px',
											// display: 'table-cell',
											verticalAlign: 'middle',
										}}
									>
										Наши роутеры ловят сигналы телефонов
									</h4>
									<img src='/img/router.png' />
								</div>
							</div>
							<div className='col-md-3 center'>
								<div
									className='white-bg'
									style={{ padding: '10px' }}
								>
									<h4
										style={{
											// height: '135px',
											// display: 'table-cell',
											verticalAlign: 'middle',
										}}
									>
										Серверы обрабатывают информацию
									</h4>
									<img src='/img/server.png' />
								</div>
							</div>
							<div className='col-md-3 center'>
								<div
									className='white-bg'
									style={{ padding: '10px' }}
								>
									<h4
										style={{
											// height: '135px',
											// display: 'table-cell',
											verticalAlign: 'middle',
										}}
									>
										Вы получаете подробную и понятную
										статистику
									</h4>
									<img
										src='/img/laptop.png'
										// style={{width: '90%', height: 'auto'}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className='center'>
					<a name='ibeacon' />
					<h1 className='center'>Маячки iBeacon™</h1>
				</div>
				<div className='ibeacons-bg'>
					<div className='container ibeacons'>
						<div className='center'>
							<h2>
								Роутеры Shopster оснащены маячками iBeacon™,
								которые позволяют определить местоположение
								человека с точностью до метра и
								прокоммуницировать с ним!
							</h2>
						</div>
						<p className='desc'>
							Теперь вы можете общаться с каждым клиентом напрямую
							– например, сообщая ему о скидках на вещи из того
							отдела, где он сейчас находится
						</p>
					</div>
				</div>
				<div className='container ibeacons-features analytics'>
					<div className='row'>
						<div className='col-md-6'>
							<div className='feature'>
								<div className='img-wrapper'>
									<img src='/img/ibeacon-gear.png' />
								</div>
								<p>
									Возможность дистанционного управления
									роутерами и маячками iBeacon™
								</p>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='feature'>
								<div className='img-wrapper'>
									<img src='/img/ibeacon-graph.png' />
								</div>
								<p>
									Интеграция статистических данных, полученных
									с помощью iBeacon™, в общую аналитику
								</p>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-6'>
							<div className='feature'>
								<div className='img-wrapper'>
									<img src='/img/ibeacon-decisions.png' />
								</div>
								<p>
									Возможность создания индивидуальной
									стратегии общения с клиентом на основе
									истории его посещений
								</p>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='feature'>
								<div className='img-wrapper'>
									<img src='/img/ibeacon-api.png' />
								</div>
								<p>
									Доступ к API – инструменту для разработчиков
								</p>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-6'>
							<div
								className='feature'
								style={{ paddingTop: '60px' }}
							>
								<p>
									Высокая мощность и отсутствие батареек:
									маячки не ограничены зарядом батареи, и
									поэтому отлично работают в любых условиях.
								</p>
							</div>
						</div>
						<div className='col-md-6'>
							<div
								className='feature'
								style={{ paddingTop: '60px' }}
							>
								<p>
									Высокая частота обновления: Маячки Shopster
									Beacon могут использоваться для самых
									сложных сценариев: навигации внутри зданий,
									безопасность и др.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='black-bg' style={{ paddingTop: '1px' }}>
					<div className='center'>
						<a name='navigation' />
						<h1 className='center'>Навигация внутри зданий</h1>
					</div>
					<div className='container'>
						<div
							className='row segments'
							style={{
								marginBottom: '60px',
								borderBottom: '1px solid #222',
							}}
						>
							<div className='col-md-6 center'>
								<img src='/img/navigation-phone.png' />
							</div>
							<div
								className='col-md-6 left'
								style={{ marginTop: '120px' }}
							>
								<p style={{ color: 'white' }}>
									Мы разработали продвинутую технологию
									позиционирования внутри зданий, которая
									использует маячки iBeacon, данные об
									искажениях геомагнитного поля и встроенные в
									телефон датчики. Наша технология позволяет
									гостям видеть себя на карте и легко находить
									интересующие магазины, а владельцам и
									арендаторам ТЦ коммуницировать с ними и
									увеличивать продажи. Мы гордимся тем, что
									первыми внедрили навигацию внутри зданий в
									одном из крупнейших ТЦ Москвы.
								</p>
							</div>
						</div>
						{/*<iframe src="https://youtu.be/hKoDmyQaQes" width={980} height={554} frameBorder={0} webkitallowfullscreen="webkitallowfullscreen" mozallowfullscreen="mozallowfullscreen" allowFullScreen="allowfullscreen" />*/}
						<YouTube videoId='hKoDmyQaQes' opts={opts} />
					</div>
				</div>
				<div
					className='container analytics'
					style={{ borderBottom: 'none' }}
				>
					<div className='center'>
						<a name='hotspot' />
						<h1 className='center'>Shopster HotSpot</h1>
					</div>
					<div className='row segments'>
						<div className='col-md-6 center'>
							<img src='/img/hotspot-phone.png' />
						</div>
						<div
							className='col-md-6 left'
							style={{ marginTop: '120px' }}
						>
							<p>
								Вы сможете настроить собственную стартовую
								страницу Wi-Fi с фирменным стилем вашего
								бизнеса. Авторизация через соц. сети и SMS
								позволит соответствовать Федеральному закону
								Российской Федерации от 5 мая 2014 г. N 97-ФЗ.
							</p>
						</div>
					</div>
					<div className='row segments' style={{ marginTop: '40px' }}>
						<div className='col-md-4'>
							<p>
								Мы покажем вам базу данных гостей,
								авторизовавшихся через ваш Wi-Fi. Вы сможете
								использовать эти данные для коммуникации с ними
								и увеличения продаж.
							</p>
						</div>
						<div className='col-md-8'>
							<img src={ImageProfile} />
						</div>
					</div>
					<div className='row segments'>
						<div
							className='col-md-7'
							style={{ marginTop: '60px', marginBottom: '60px' }}
						>
							<img src='/img/hotspot-checkin.png' />
						</div>
						<div
							className='col-md-5 right'
							style={{ textAlign: 'right', marginTop: '100px' }}
						>
							<p style={{ marginTop: '40px' }}>
								Подключаясь к Wi-Fi через соц. сети, ваши гости
								будут автоматически оставлять рекламные
								сообщения, лайки и вступать в группы. Так друзья
								ваших гостей будут узнавать о вашем заведении.
							</p>
						</div>
					</div>
					<div
						className='row segments'
						style={{ marginTop: '40px', border: 'none' }}
					>
						<div className='col-md-4 right'>
							<p style={{ marginTop: '60px', paddingBottom: 0 }}>
								Мы поделим ваших гостей на группы и покажем вам
								лояльных, случайных, вовлеченных и невовлеченных
								покупателей и сотрудников.
							</p>
						</div>
						<div className='col-md-8'>
							<img src={ImageSegment} />
						</div>
					</div>
				</div>

				<div
					className='center'
					style={{
						paddingBottom: '40px',
						borderTop: '1px solid #ccc',
					}}
				>
					<DivBoxText>
						<DivBoxListItem>
							<SpanListTitle>
								<p>Стоимость ПО определяется индивидуально.</p>
								<p>
									Для получения коммерческого предложения,
									отправьте заявку на почту{' '}
									<a href='mailto:info@getshopster.net'>
										info@getshopster.net
									</a>
									. В заявке необходимо указать следующие
									данные:
								</p>
							</SpanListTitle>
						</DivBoxListItem>
						{/*<DivBoxListItem>*/}
						{/*	<SpanListTitle>*/}
						{/*		<a href="mailto:info@getshopster.net">info@getshopster.net</a>*/}
						{/*	</SpanListTitle>*/}
						{/*</DivBoxListItem>*/}
						{/*<DivBoxListItem>*/}
						{/*	<SpanList>В заявке необходимо указать следующие данные:</SpanList>*/}
						{/*</DivBoxListItem>*/}
						<DivBoxListItem>
							<SpanList>
								<ul>
									<li>
										<p>Юридическое наименование компании</p>
									</li>
									<li>
										<p>
											Какой тип бизнеса вы представляете
											(ТРЦ/Ритейл/HoReCa/Другое)
										</p>
									</li>
									<li>
										<p>
											Контактные данные для связи с вами
										</p>
									</li>
									<li>
										<p>
											К заявке дополнительно необходимо
											приложить План объекта
										</p>
									</li>
								</ul>
							</SpanList>
						</DivBoxListItem>
						{/*<DivBoxListItem>*/}
						{/*	<SpanList>2. Какой тип бизнеса вы представляете (ТРЦ/Ритейл/HoReCa/Другое)</SpanList>*/}
						{/*</DivBoxListItem>*/}
						{/*<DivBoxListItem>*/}
						{/*	<SpanList>3. Приложите план объекта с масштабами</SpanList>*/}
						{/*</DivBoxListItem>*/}
						{/*<DivBoxListItem>*/}
						{/*	<SpanList>4. Укажите контактные данные для связи с вами</SpanList>*/}
						{/*</DivBoxListItem>*/}
					</DivBoxText>

					<button
						onClick={openModal}
						className='btn btn-primary btn-lg'
						data-toggle='modal'
						data-target='#orderModal'
						style={{
							marginBottom: '40px',
							marginTop: '20px',
						}}
					>
						Оставить заявку
					</button>
					<div className='container'>
						<h1 className='center'>ОСНОВНЫЕ КОНТАКТЫ</h1>
						<p>
							Тел.:{' '}
							<a href='tel:+7 499 455-45-16'>
								+7 (499) 455-45-16
							</a>
						</p>
						{/*<p>Телефон службы поддержки: <a href="tel:+7 495 114-90-28,705">+7 495 114-90-28 доб. 705</a></p>*/}
						<p>
							E-mail:{' '}
							<a href='mailto:info@getshopster.net'>
								info@getshopster.net
							</a>
						</p>
						<p>
							Адрес: 121205, г. Москва, территория инновационного
							центра «Сколково»,Большой бульвар, д. 42, стр. 1, 3
							эт., помещение №1112, раб. место №1
						</p>
					</div>
				</div>
				<div className='boxLaflet'>
					<YMaps>
						<div className={'boxLafletMaps'}>
							<Map
								width={'100%'}
								height={'100%'}
								defaultState={stateMaps}
							>
								<Placemark geometry={stateMaps.center} />
							</Map>
						</div>
					</YMaps>
				</div>
				<div
					className='center'
					style={{
						paddingBottom: '40px',
						borderTop: '1px solid #ccc',
					}}
				>
					<div className='container'>
						<table style={{ margin: '60px auto' }}>
							<tbody>
								<tr>
									<td>СВЕДЕНИЯ</td>
									<td>ИМЕЮЩИЕСЯ ДАННЫЕ</td>
								</tr>
								<tr>
									<td>
										Полное наименование юридического лица
									</td>
									<td>
										Общество с ограниченной ответственностью
										«Шопстер Технологии»
									</td>
								</tr>
								{/*<tr>*/}
								{/*	<td>Форма собственности</td>*/}
								{/*	<td>ООО</td>*/}
								{/*</tr>*/}
								<tr>
									<td>ИНН / КПП</td>
									<td>7706457835/773101001</td>
								</tr>
								<tr>
									<td>ОГРН</td>
									<td>1187746825496</td>
								</tr>
								<tr>
									<td>Генеральный директор</td>
									<td>Котелевская Анна Алексеевна</td>
								</tr>
								<tr>
									<td>Юридический адрес</td>
									<td>
										121205, г. Москва, территория
										инновационного центра «Сколково»,
										Большой бульвар, д. 42, стр. 1, 3 эт.,
										помещение №1112, раб. место №1
									</td>
								</tr>
								{/*<tr>*/}
								{/*	<td>Почтовый адрес</td>*/}
								{/*	<td>123007, Москва, Хорошевское шоссе, 32А, корп. 24 (подъезд 2, этаж 2)</td>*/}
								{/*</tr>*/}
							</tbody>
						</table>
					</div>
				</div>
				<BoxModal
					className={statusOpenModal ? `modal fade in` : `modal fade`}
					id='orderModal'
					statusOpenModal={statusOpenModal}
					onClick={hideModal}
				>
					<div className='modal-dialog'>
						<div
							className='modal-content'
							style={{ margin: 'auto' }}
						>
							<div className='modal-header'>
								<button
									onClick={hideModal}
									id={'close'}
									className='close'
									type='button'
									data-dismiss='modal'
									aria-hidden='true'
								>
									×
								</button>
								<h4 className='center' style={{ margin: 0 }}>
									Заказать систему Shopster
								</h4>
							</div>
							<div
								className='modal-body'
								style={{
									marginLeft: 'auto',
									marginRight: 'auto',
									width: '360px',
								}}
							>
								<form
									onSubmit={submitForm}
									action='https://formspree.io/f/mjvpoyny'
									method='POST'
								>
									<div className='form-group'>
										<input
											className='form-control'
											id='name'
											type='text'
											name='name'
											placeholder='Имя'
										/>
									</div>
									<div className='form-group'>
										<input
											className='form-control'
											id='email'
											type='email'
											name='email'
											placeholder='email'
										/>
									</div>

									<div className='form-group'>
										<input
											className='form-control'
											id='tel'
											type='tel'
											name='tel'
											placeholder='Телефон'
										/>
									</div>
									<div className='form-group'>
										<input
											className='form-control'
											id='buisiness_type'
											type='text'
											name='buisiness_type'
											placeholder='Тип бизнеса'
										/>
									</div>
									<div className='form-group'>
										<textarea
											className='form-control'
											id='message'
											name='message'
											placeholder='Сообщение'
											defaultValue={''}
										/>
									</div>

									<button
										disabled={status}
										type='submit'
										style={{
											display: 'block',
											margin: 'auto',
										}}
									>
										Отправить
									</button>

									{status === 'SUCCESS' ?
										<p>Thanks!</p>
									:	null}
									{status === 'ERROR' && (
										<p>Ooops! There was an error.</p>
									)}
								</form>
							</div>
						</div>
					</div>
				</BoxModal>
			</div>
		</div>
	);
};

export default MainContent;
