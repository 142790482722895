import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import WrapperFolder from "./components/WrapperFolder";
import WrapperPdf from "./components/WrapperPdf";

const MainFunctional = () => {
	const [idDocument, setIdDocument] = useState(1)
	const [idDocumentTemp, setIdDocumentTemp] = useState(1)
	
	useEffect(() => {
		setIdDocumentTemp(0)
		setTimeout(() => {
			setIdDocumentTemp(idDocument)
		}, 100);
	}, [
		idDocument
	]);
	
	return (
		<WrapperContent>
			<WrapperFolder
				setIdDocument={setIdDocument}
				idDocument={idDocumentTemp}
			/>
			<WrapperPdf
				idDocument={idDocumentTemp}
			/>
		</WrapperContent>
	);
};

const WrapperContent = styled.div`
  width: 100%;
  height: calc(100% - 58px);
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 100%;
`

export default MainFunctional;
